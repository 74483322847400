import React from 'react';
import PropTypes from 'prop-types';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';

require("prismjs/themes/prism-coy.css");

/**
 * Component that enables input text(area) with syntax highlighting.
 *
 * @fero
 */

class CodeEditor extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {value, onChange, ...props} = this.props;
        const valueChecked = value != null ? value : "";
        return <Editor
            value={valueChecked}
            onValueChange={onChange}
            highlight={ code => Prism.highlight(code, Prism.languages["php"])}
            tabSize={1}
            insertSpaces={false}
            padding={10}
            style={{
                border: '1px solid #bbb'
            }}
            {...props}
        />;
    }
}

export default CodeEditor;