import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import FormsNavigation from './forms/FormsNavigation';
import FormForm from './forms/FormForm';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {navigateToParametrized, getQueryParam, getQueryParamNumber} from '../../lib/url';
import {createFetchFormAdd, createFetchFormEdit, createFetchFormDetails} from '../../backend/apiCalls';
import {Forms, Location} from '../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS} from '../../constants/navigation';
import {Trans, t} from '@lingui/macro';
import ListWithTableLayout from '../project/ListWithTableLayout';
import update from 'immutability-helper';

/**
 * @fero
 */

class FormsPage extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        [GLOBAL_DATA.FORMS]: Forms.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forms: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            formDetails: {},
            activeTab: undefined,
        };
    }

    setActiveTab = (key) => {
        this.setState({activeTab: key});
    };

    componentDidMount() {
        const {location} = this.props;
        const formId = getQueryParam(location, QUERY_PARAMS.ID_FORM);
        if(formId != null) {
            this.fetchFormDetails(formId);
        }
        this.setState({activeTab: formId != null ? 'table' : 'list'});
    }

    componentDidUpdate(prevProps) {
        const {location} = this.props;
        const {location: prevLocation} = prevProps;
        const formId = getQueryParam(location, QUERY_PARAMS.ID_FORM);
        const prevFormId = getQueryParam(prevLocation, QUERY_PARAMS.ID_FORM);
        if(formId != prevFormId) {
            if(formId != null) 
            {
                this.fetchFormDetails(formId);
            } 
            else 
            {
                this.setState({formDetails: {}})
            }
            this.setState({activeTab: 'table'});
        }
    }

    onFormSelect = (formId) => {
        const {location} = this.props;
        this.setState({activeTab: 'table'});
        navigateToParametrized(location, null, {[QUERY_PARAMS.ID_FORM]: formId});
    };

    fetchFormDetails = (formId) => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        fetchHandler(
            createFetchFormDetails(),
            {id: formId},
            (result) => {
                this.setState({
                    formDetails: result,
                    activeTab: 'table',
                })
            }
        )
    };

    onFormUpdate = (updateDef) => {
        const {formDetails} = this.state;
        const updatedForm = update(formDetails, updateDef);
        this.setState({formDetails: updatedForm});
    };

    render() {
        const {
            [GLOBAL_DATA.FORMS]: forms,
            [GLOBAL_DATA.RELOAD_DATA]: reload,
            location
        } = this.props;
        const {formDetails, activeTab} = this.state;
        const selectedFormId = getQueryParamNumber(location, QUERY_PARAMS.ID_FORM);
        const isEdit = selectedFormId != null;
        return <React.Fragment>
            <Helmet
                title={t`Kustomizácie`}
            />
            <ListWithTableLayout
                title={<Trans>Kustomizácie</Trans>}
                list={<FormsNavigation
                    forms={forms}
                    onFormSelect={this.onFormSelect}
                    reloadForms={() => {
                        reload([GLOBAL_DATA.FORMS])
                    }}
                    selectedFormId={selectedFormId}
                />}
                listTitle={<Trans>Všetky</Trans>}
                table={<div className="full-size-width full-size-height overflow-auto">
                    <FormForm
                        formDetails={formDetails}
                        isEdit={isEdit}
                        fetchFunc={isEdit ? createFetchFormEdit(formDetails) : createFetchFormAdd(formDetails)}
                        updateForm={this.onFormUpdate}
                        onFinishSuccessful={(result) => {
                            if(isEdit)
                                this.fetchFormDetails(selectedFormId);
                            else
                                this.fetchFormDetails(result);
                                
                            reload([GLOBAL_DATA.FORMS]);
                        }}
                    />
                </div>}
                tableTitle={<Trans>Detaily</Trans>}
                activeTab={activeTab}
                setActiveTab={this.setActiveTab}
            />
        </React.Fragment>;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.FETCH_HANDLER, GLOBAL_DATA.FORMS, GLOBAL_DATA.RELOAD_DATA])(FormsPage));