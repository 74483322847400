import React from 'react';
import PropTypes from 'prop-types';
import OptionFormPart from './form/OptionFormPart';
import InputText from '../../general/InputText';
import CodeEditor from '../../general/CodeEditor';
import {Trans, t} from '@lingui/macro';
import {Button, Radio} from 'antd';
import {focus, getInputOnChangeEventValue} from '../../../lib/project';
import InfoAttribute from '../../general/InfoAttribute';
import ButtonFetch from '../../fetch/ButtonFetch';
import generalFailedPC from '../../fetch/generalFailedPC';

/**
 * @fero
 */

class FormForm extends React.PureComponent {
    static propTypes = {
        formDetails: PropTypes.object,
        updateForm: PropTypes.func.isRequired,
        fetchFunc: PropTypes.func.isRequired,
        onFinishSuccessful: PropTypes.func.isRequired,
    };

    componentDidMount() {
        focus(this.first);
    }

    render() {
        const {formDetails = {}, updateForm, fetchFunc, onFinishSuccessful} = this.props;
        return <div className="p-3">
            <InfoAttribute
                attributeTitle={<Trans>Názov:</Trans>}
                attributeValue={
                    <InputText
                        ref={node => this.first = node}
                        value={formDetails.name}
                        onChange={(newName) => updateForm({name: {$set: newName}})}
                    />
                }
                isRequired={true}
                hasError={formDetails.name == null || formDetails.name.trim() == ''}
            />
            <InfoAttribute
                attributeTitle={<Trans>Je povinné:</Trans>}
                attributeValue={
                    <Radio.Group 
                        value={formDetails.is_required} 
                        onChange={(e) => {
                            updateForm({is_required: {$set: getInputOnChangeEventValue(e)}})
                        }}
                    >
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                }
                isRequired={true}
            />
            <InfoAttribute
                attributeTitle={<Trans>Validator:</Trans>}
                attributeValue={
                    <CodeEditor
                        autoSize={{
                            minRows: 5,
                        }}
                        value={formDetails.validator}
                        onChange={(newCode) => updateForm({validator: {$set: newCode}})}
                    />
                }
                isRequired={true}
            />
            <InfoAttribute
                attributeTitle={<Trans>Designator:</Trans>}
                attributeValue={
                    <CodeEditor
                        autoSize={{
                            minRows: 5,
                        }}
                        value={formDetails.designator}
                        onChange={(newCode) => updateForm({designator: {$set: newCode}})}
                    />
                }
                isRequired={true}
            />
            <InfoAttribute
                attributeTitle={<Trans>Consumer:</Trans>}
                attributeValue={
                    <CodeEditor
                        autoSize={{
                            minRows: 5,
                        }}
                        value={formDetails.consumer}
                        onChange={(newCode) => updateForm({consumer: {$set: newCode}})}
                    />
                }
                isRequired={true}
            />
            <h3 className="p-3 text-center">
                <span className="mr-2 text-danger">*</span>
                <Trans>Možnosti</Trans>
            </h3>
            { formDetails.options != null && Array.isArray(formDetails.options) ? 
                formDetails.options.map((option, key) => {
                    return <OptionFormPart
                        formOption={option}
                        index={key}
                        key={key}
                        updateOption={(obj) => updateForm({options: {[key]: obj}})}
                        removeOption={() => updateForm({options: { $splice: [[key, 1]] }})}
                    />
                }) : null
            }
            <Button
                icon="plus"
                onClick={() => {
                    if(formDetails.options != null)
                        updateForm({options: {$push: [{}]}})
                    else
                        updateForm({options: {$set: [{}]}})
                }}
            >
                <Trans>Pridať možnosť</Trans>
            </Button>
            <div className="p-3 text-center">
                <ButtonFetch
                    type="primary"
                    values={{}}
                    fetchFunction={fetchFunc}
                    onFinish={onFinishSuccessful}
                    Response={EditFormResponse}
                    Failed={generalFailedPC(t`Ukladanie kustomizácie zlyhalo.`)}
                >
                    <Trans>Uložiť</Trans>
                </ButtonFetch>
            </div>
        </div>;
    }

}

export default FormForm;

class EditFormResponse extends React.PureComponent {
    render() {
        return <h4>{t`Kustomizácia bola úspešne uložená.`}</h4>;
    }
}