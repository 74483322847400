import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Parameters} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import Select from '../general/Select';

/**
 * @fero
 */

class ParametersSelect extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.PARAMETERS]: Parameters.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
        focus: PropTypes.bool,
        allowClear: PropTypes.bool,
    };

    render() {
        const {[GLOBAL_DATA.PARAMETERS]: parameters, ...props } = this.props;
        return <Select
            {...props}
            options={parameters.map(parameter => {
                return {
                    label: parameter.name,
                    value: parameter.id,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.PARAMETERS])(ParametersSelect);