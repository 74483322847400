import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import {FORM_TYPES} from '../../constants/form';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class FormOptionTypeSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onChangeFormId: PropTypes.func,
    };

    render() {
        const { ...props } = this.props;
        const options = Object.keys(FORM_TYPES).map(key => {
            const type = FORM_TYPES[key] != null ? FORM_TYPES[key] : {};
            return {
                label: type.label,
                value: type.key,
            }
        });
        return <Select
            {...props}
            options={options}
        />;
    }

}

export default FormOptionTypeSelect;