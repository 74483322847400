import React from 'react';
import PropTypes from 'prop-types';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { Tabs } from 'antd';
const { TabPane } = Tabs;


/**
 * @fero
 */

class ListWithTableLayout extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
        listTitle: PropTypes.node.isRequired,
        list: PropTypes.node.isRequired,
        tableTitle: PropTypes.node.isRequired,
        table: PropTypes.node.isRequired,
        activeTab: PropTypes.oneOf(['table', 'list']),
        setActiveTab: PropTypes.func.isRequired,
    };

    render() {
        const {
            title, listTitle, list, tableTitle, table, activeTab, setActiveTab, 
            [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile
        } = this.props;
        return isMobile ? 
        <div className="full-size-height d-flex flex-column">
            <div className="flex-item-static">
                <h2 className="text-center">{title}</h2>
            </div>
            <div style={{height: 'calc(100% - 3rem)'}}>
                <Tabs activeKey={activeTab} onChange={setActiveTab} className="full-size-tabs">
                    <TabPane key="list" tab={listTitle}>
                        {list}
                    </TabPane>
                    <TabPane key="table" tab={tableTitle}>
                        {table}
                    </TabPane>
                </Tabs>
            </div>
        </div> :
        <div className="full-size-height d-flex">
            <div className="flex-item-static full-size-height left-navigation">
                {list}
            </div>
            <div className="flex-item-dynamic-1 full-size-height overflow-hidden">
                {table}
            </div>
        </div>;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(ListWithTableLayout);